import { Contract } from "@ethersproject/contracts";
import { RPC_URL } from "src/constants";
import Web3 from "web3";
import moment from "moment";
export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 4)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Obj = () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress) => {
  const web3 = getWeb3Obj(RPC_URL);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      return timeLeft;
    } else {
      return "live";
    }
  } else {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
};
export const calculateTimeLeftForRace = (endDate) => {
  if (endDate) {
    // let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (endDate > 0) {
      timeLeft = {
        days: Math.floor(endDate / (1000 * 60 * 60 * 24)),
        hours: Math.floor((endDate / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((endDate / 1000 / 60) % 60),
        seconds: Math.floor((endDate / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
export const findEventStatus = (startDate, endDate) => {
  if (startDate && endDate) {
    if (moment(endDate).unix() < moment().unix()) {
      return "Ended";
    } else if (
      moment().unix() < moment(endDate).unix() &&
      moment().unix() > moment(startDate).unix()
    ) {
      return "Live";
    } else {
      return "Upcoming";
    }
  }
};
